import React, { useState, useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import { IoIosArrowRoundBack, IoIosCheckmarkCircle } from "react-icons/io";
import avatar from "../../assets/lady.jpg"; // Assuming this is a default image
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "../../redux-store/postSearch/searchSlice";
import { FaUserCircle } from "react-icons/fa";

const ChatPersonDirect = ({ userDetails, postDescriptionall }) => {
  const navigate = useNavigate();
  const [userLastmsg, setUserLastMessage] = useState("");
  const [expandedMessages, setExpandedMessages] = useState({});
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    const formattedString = string.replace(/-/g, " ");
    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };

  const handleTagClick = (tag) => {
    dispatch(setSearchText(tag));
    navigate("/explore");
  };

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="py-3 bg-white rounded-xl mb-3 w-full px-8">
      <IoIosArrowRoundBack
        className="mt- ml-2 mb-2 text-3xl cursor-pointer hover:scale-95"
        onClick={() => navigate("/messages")} // Navigate back one step
      />
      {userDetails && (
        <div className="flex">
          <Link to={`/user/${userDetails.id}`}>
            {userDetails.profile_image !== null &&
            userDetails.profile_image !== "default.png" ? (
              <img
                className={`w-12 h-12 rounded-full p-0 object-cover text-[#E7831D] ${
                  userDetails.type?.toLowerCase() === "employer"
                    ? "ring-2 ring-[#E7831D]"
                    : "ring-2 ring-neutral-700"
                }`}
                src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${userDetails.profile_image}`}
                alt="User Avatar"
                style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
              />
            ) : (
              <div className="flex relative">
                <FaUserCircle
                  className={`w-12 h-12 rounded-full p-0 object-cover text-neutral-300 ${
                    userDetails.type?.toLowerCase() === "employer"
                      ? "ring-2 ring-[#E7831D]"
                      : "ring-2 ring-neutral-700"
                  }`}
                  onClick={() => navigate(`/user/${userDetails.id}`)}
                />
              </div>
            )}
          </Link>
          <div className="flex flex-col ml-2">
            <Link to={`/user/${userDetails.id}`}>
              <span className="text-lg font-bold text-neutral-600 cursor-pointer">
                {userDetails.first_name} {userDetails.last_name}
              </span>
            </Link>

            <span
              className={`text-sm flex items-center ${
                userDetails.type === "job-seeker"
                  ? "text-black"
                  : "text-[#E7831D]"
              }`}
            >
              <span
                className={`text-sm text-[#5B5B5B] ${
                  userDetails.username === "@premium" ||
                  userDetails.username === "@Test"
                    ? "text-[#5B5B5B]"
                    : ""
                }`}
              >
                {userDetails.username}
              </span>
              <GoDotFill className="ml-1 text-xs mr-1" />
              {capitalizeFirstLetter(userDetails.type)}
              <IoIosCheckmarkCircle className="ml-1 text-lg" />
            </span>
          </div>
        </div>
      )}

      <div className="mt-3 ml-14 mb-3">
        <div>
          {isExpanded
            ? postDescriptionall.split(" ").map((word, index) =>
                word.includes("#") ? (
                  <span key={index} className="text-[#E7831D] font-bold">
                    {word}{" "}
                  </span>
                ) : (
                  <span key={index}>{word} </span>
                )
              )
            : postDescriptionall.length > 200
            ? postDescriptionall
                .substring(0, 200)
                .split(" ")
                .map((word, index) =>
                  word.includes("#") ? (
                    <span key={index} className="text-[#E7831D] font-bold">
                      {word}{" "}
                    </span>
                  ) : (
                    <span key={index}>{word} </span>
                  )
                )
            : postDescriptionall.split(" ").map((word, index) =>
                word.includes("#") ? (
                  <span key={index} className="text-[#E7831D] font-bold">
                    {word}{" "}
                  </span>
                ) : (
                  <span key={index}>{word} </span>
                )
              )}
          {postDescriptionall.length > 200 && (
            <span
              className="text-[#E7831D] cursor-pointer"
              onClick={toggleDescription}
            >
              {isExpanded ? " Read less" : " Read more"}
            </span>
          )}
        </div>

        <div></div>
      </div>
      <hr />
    </div>
  );
};

export default ChatPersonDirect;
