import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { AiOutlineClose, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import searchicon from "../assets/radix-icons_magnifying-glass@2x.svg";
import axios from "axios";
import { List } from "antd";
import {
  setSearchText,
  clearSearchText,
} from "../redux-store/postSearch/searchSlice";
import {
  setUserSearchText,
  clearUserSearchText,
} from "../redux-store/userSearch/userSearchSlice";
import { MdExpandLess } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";
import { toggleActiveTag } from "../redux-store/tagFilter/tagSlice";
import Tags from "./mini-components/Tags";
import translations from "../languages";
import { IoClose } from "react-icons/io5";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Popover } from "@headlessui/react";

const Explore = ({ activeComponent }) => {
  const location = useLocation();
  const isBookmarksRoute = location.pathname.includes("/explore");
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const searchText = useSelector((state) => state.search.searchText);
  const activeTags = useSelector((state) => state.tag.activeTags);
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const userSearchText = useSelector(
    (state) => state.userSearch.userSearchText
  );
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;
  const tagNames = translations[selectedLanguage].tags;
  const [searchHistory, setSearchHistory] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(true);

  const options = {
    emp_post: tagNames.emp_post,
    job_seeker_post: tagNames.job_seeker_post,
    emp_profile: tagNames.emp_profile,
    job_seeker_profile: tagNames.job_seeker_profile,
  };

  useEffect(() => {
    if (activeComponent !== texts.home && activeComponent !== texts.explore) {
      handleClearSearch();
    }
  }, [activeComponent, texts.home, texts.explore]);

  const handleTagClick = (tag) => {
    const isActive = activeTags.includes(tag);
    dispatch(toggleActiveTag(tag));

    if (isActive) {
      // Remove the tag from local storage if it is unselected
      localStorage.removeItem("selectedTag");
    } else {
      // Save the selected tag to local storage
      localStorage.setItem("selectedTag", tag);
    }
  };

  const handleInputChange = async (e) => {
    const value = e.target.value;
    dispatch(setUserSearchText(value));
    setQuery(value);

    // Save the query to local storage
    localStorage.setItem("searchQuery", value);

    if (value === "") {
      handleClearSearch(); // Clear the suggestions and history if the input is empty
    } else {
      try {
        // Fetch suggestions based on the query
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/tags?string=${value}`
        );
        setSuggestions(response.data.content.map((item) => item.tag));

        // Fetch search history simultaneously
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");

        const historyResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/taghistory?limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSearchHistory(historyResponse.data.content);
      } catch (error) {
        console.error("Error fetching suggestions or search history:", error);
      }
    }
  };

  const handleIconClick = () => {
    // Reuse the same logic for fetching suggestions and search history on icon click
    if (query) {
      handleInputChange({ target: { value: query } });
    }
  };

  const handleClearSearch = () => {
    dispatch(clearSearchText());
    dispatch(clearUserSearchText());
    setQuery("");
    setSearchHistory([]);
    setSuggestions([]);
  };

  const handleSuggestionClick = (suggestion) => {
    dispatch(setSearchText(suggestion));
    setQuery("");
    dispatch(clearUserSearchText());
    setSuggestions([]);
    setSearchHistory([]);
  };

  const highlightMatch = (text, highlight) => {
    if (typeof text !== "string") {
      return text;
    }

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="font-bold text-orange-400">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="p-2 flex flex-col justify-start items-start bg-white ml-2 relative suggestion-layer z-[9999999999999999999] ">
      <span className="text-lg font-bold text-black flex-1">
        {activeComponent === texts.home ? texts.explore : activeComponent}
      </span>
      <div className="flex flex-col w-full mt-3 relative px-1">
        <div className="flex space-x- h-12 rounded-full border-2 border-neutral-400 outline-none px-5 items-center">
          <input
            type="text"
            className="outline-none flex-grow ml-2"
            placeholder="Type here"
            value={query || searchText}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && query) {
                handleSuggestionClick(query);
              }
            }}
          />
          <img
            src={searchicon}
            alt=""
            className={`h-6 w-6 shrink-0 group-hover:hidden cursor-pointer transition-transform duration-150 ${
              clicked ? "scale-75" : "scale-100"
            }`}
            onClick={() => handleSuggestionClick(query)}
          />
          {query && (suggestions.length > 0 || searchHistory.length > 0) && (
            <div
              className="bg-white rounded-xl mt-2 mb-2 scrollbar-hide w-full top-12 shadow-sm left-0 z-[99999999999999] absolute px-1"
              style={{ maxHeight: "450px", overflowY: "auto" }}
            >
              <div className="px-4 py-2 bg-slate-100 text-neutral-600 text-xs italic flex">
                {suggestions.length === 0 && searchHistory.length === 0
                  ? "0 search results"
                  : `${suggestions.length} search result${
                      suggestions.length > 1 ? "s" : ""
                    } and ${searchHistory.length} history item${
                      searchHistory.length > 1 ? "s" : ""
                    }`}
                {isSuggestionsVisible ? (
                  <MdExpandLess
                    className="cursor-pointer text-neutral-700 ml-auto items-center text-xl"
                    onClick={() => setIsSuggestionsVisible(false)}
                  />
                ) : (
                  <MdExpandMore
                    className="cursor-pointer text-neutral-700 ml-auto items-center text-xl"
                    onClick={() => setIsSuggestionsVisible(true)}
                  />
                )}
              </div>
              {isSuggestionsVisible && (
                <>
                  {/* Display search history */}
                  {searchHistory.length > 0 && (
                    <List
                      bordered
                      dataSource={searchHistory}
                      className="bg-slate-50 w-full z-50 mr-5 border-none"
                      renderItem={(historyItem) => (
                        <List.Item
                          className="cursor-pointer flex-wrap "
                          onClick={() => handleSuggestionClick(historyItem.tag)}
                        >
                          <span className="flex w-full">
                            {highlightMatch(historyItem.tag, query)}{" "}
                            <AiOutlineClockCircle className="text-neutral-500 top-0 right-1 ml-auto text-xl z-[999999999999999999999]" />
                          </span>
                        </List.Item>
                      )}
                    />
                  )}
                  <hr />
                  <List
                    bordered
                    dataSource={suggestions}
                    className="bg-slate-50 w-full z-50 mr-5 border-none"
                    renderItem={(suggestion) => (
                      <List.Item
                        className="cursor-pointer"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {highlightMatch(suggestion, query)}
                      </List.Item>
                    )}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isBookmarksRoute && (
        <div className={!isSuggestionsVisible ? "mt-10" : ""}>
          {" "}
          <Tags
            options={options}
            activeTags={activeTags}
            onTagClick={handleTagClick}
          />
        </div>
      )}
    </div>
  );
};

export default Explore;
