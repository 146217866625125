import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "antd";
import AboutMeModal from "./settingsModals/AboutMeModal";
import UserNameModal from "./settingsModals/UserNameModal";
import PhoneNumberModal from "./settingsModals/PhoneNumberModal";
import MyPasswordModal from "./settingsModals/MyPasswordModal";
import RoleModal from "./settingsModals/RoleModal";
import profileIcon from "../../assets/Profile.svg";
import userNameIcon from "../../assets/username.svg";
import emailIcon from "../../assets/Message.svg";
import keyIcon from "../../assets/Key.svg";
import roleIcon from "../../assets/Role.svg";
import editIcon from "../../assets/edit.svg";
import phoneIcon from "../../assets/phone.svg";
import { useSelector } from "react-redux";
import translations from "../../languages";
import { IoClose } from "react-icons/io5";
import PleaseLoginModal from "../../pages/PleaseLoginModal";

const SettingsListOne = () => {
  const [activeModal, setActiveModal] = useState(null);
  const [userData, setUserData] = useState({});
  const [expandedKeys, setExpandedKeys] = useState([]);
  const selectedLanguage = useSelector((state) => state.language.language);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const texts = translations[selectedLanguage].settingsOne;

  const settingsOne = [
    {
      name: texts.aboutMe,
      canEdit: true,
      icon: profileIcon,
      modal: AboutMeModal,
      key: "about_me",
    },
    {
      name: texts.username,
      canEdit: true,
      icon: userNameIcon,
      modal: UserNameModal,
      key: "username",
    },
    {
      name: texts.email,
      canEdit: false,
      icon: emailIcon,
      key: "email",
    },
    {
      name: texts.phone,
      canEdit: true,
      icon: phoneIcon,
      modal: PhoneNumberModal,
      key: "phone",
    },
    {
      name: texts.myPassword,
      canEdit: true,
      icon: keyIcon,
      modal: MyPasswordModal,
      key: "password",
    },
    {
      name: texts.role,
      canEdit: true,
      icon: roleIcon,
      modal: RoleModal,
      key: "type",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(response.data.content);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const openModal = (ModalComponent, data) => {
    setActiveModal(
      <ModalComponent
        closeModal={closeModal}
        data={data}
        userData={userData}
        fetchUserData={fetchData}
        closeIcon={<IoClose className="text-neutral-50" />}
      />
    );
  };

  const closeModal = () => {
    setActiveModal(null);
    fetchData();
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);
  const toggleExpansion = (key) => {
    if (expandedKeys.includes(key)) {
      setExpandedKeys(expandedKeys.filter((k) => k !== key));
    } else {
      setExpandedKeys([...expandedKeys, key]);
    }
  };

  const formatMessage = (message) => {
    if (!message) {
      return ""; // Return an empty string or handle the null case as needed
    }
    return message.replace(
      /#(\w+)/g,
      '<span class="font-bold text-orange-500">#$1</span>'
    );
  };

  // Helper function to format the role string
  const formatRole = (role) => {
    if (!role) return "";
    return role
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const onsubmit = (item) => {
    if (userExists) {
      openModal(item.modal);
    } else {
      setIsLoginModalVisible(true);
    }
  };
  return (
    <>
      {activeModal}
      <ul
        role="list"
        className={`${
          !userExists ? "bg-neutral-200" : "bg-white"
        } divide-y divide-neutral-100 overflow-hidden ring-neutral-900/5 `}
      >
        {settingsOne.map((item) => (
          <li
            key={item.name}
            className="relative flex justify-between gap-x-6 px-10 py-5 sm:px-8 hover:scale-105 duration-300"
          >
            <div className="flex gap-x-4">
              <img src={item.icon} alt={item.name} className="w-6 h-6" />
              <div className="min-w-0 flex-auto">
                <p className="text-md leading-6 text-neutral-700">
                  <span className=" inset-x-0 -top-px bottom-0 text-neutral-600 z-40">
                    {item.name}
                  </span>
                </p>
                <p className="text-sm max-w-[45rem] z-50 ">
                  {userData[item.key] !== undefined ? (
                    <>
                      {expandedKeys.includes(item.key) ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              item.key === "type"
                                ? formatRole(userData[item.key])
                                : formatMessage(userData[item.key]),
                          }}
                        />
                      ) : (
                        <>
                          {typeof userData[item.key] === "string" &&
                          userData[item.key].length > 60 ? (
                            <>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item.key === "type"
                                      ? formatRole(
                                          userData[item.key].substring(0, 60)
                                        )
                                      : formatMessage(
                                          userData[item.key].substring(0, 60)
                                        ),
                                }}
                              />
                              {userData[item.key].length > 60 && (
                                <>
                                  <button
                                    onClick={() => toggleExpansion(item.key)}
                                    className="text-orange-500 ml-1 focus:outline-none cursor-pointer"
                                    style={{ zIndex: 999 }}
                                  >
                                    {texts.readMore}
                                  </button>
                                </>
                              )}
                            </>
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.key === "type"
                                    ? formatRole(userData[item.key])
                                    : formatMessage(userData[item.key]),
                              }}
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-4 z-50">
              {item.canEdit && (
                <img
                  src={editIcon}
                  alt="Edit"
                  className="w-5 h-5 flex-none text-neutral-500 cursor-pointer hover:scale-105"
                  aria-hidden="true"
                  onClick={() => onsubmit(item)}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
    </>
  );
};

export default SettingsListOne;
