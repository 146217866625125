// ForgetPasswordModal.js
import React, { useState } from "react";
import { Modal, Input, Button, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import logo from "../assets/mainlogonew.png";

const ForgetPasswordModal = ({ isModalVisible, setIsModalVisible }) => {
  const [loading, setLoading] = useState(false);
  const [identity, setIdentity] = useState("");
  const navigate = useNavigate();

  const handleModalSubmit = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const identity_type = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(identity)
      ? "email"
      : "phone";

    if (!baseUrl || !identity) {
      toast.error(" Identity information not found");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/user/forget-password/send-otp`,
        {
          identity,
          identity_type,
        }
      );

      if (response.status === 200) {
        setLoading(false);
        setIsModalVisible(false);
        localStorage.setItem(
          "identity",
          JSON.stringify({ identity, identity_type })
        );
        navigate("/verify-otp");
      } else {
        setLoading(false);
        throw new Error("Failed to send OTP");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error: " + error.message);
    }
  };

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleModalSubmit}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3 h-auto">
        <p class="font-inter font-semibold text-2xl leading-7 text-center px-12  mt-5">
          Please enter your email or phone
        </p>

        <img
          src={logo}
          alt="logo"
          className="w-full h-auto px-16 lg:py-8  md:py-10 p-10 mb-4 "
        />

        <Input
          placeholder="Enter your email or phone"
          value={identity}
          onChange={(e) => setIdentity(e.target.value)}
          className="bg-neutral-50 p-3 rounded-full mb-2 border-2 border-[#818181] outline-none mt-3 w-10/12 "
        />
        <button
          className="p-3 bg-[#404041] hover:bg-[#585858] text-white font-bold rounded-full shadow-sm text-xl  hover:scale-95 hover:shadow-lg w-10/12 lg:mt-4 mt-0"
          onClick={handleModalSubmit}
        >
          {loading && (
            <Space className="mr-3">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#fff" }}
                    spin
                  />
                }
                size="medium"
              />
            </Space>
          )}
          Submit
        </button>

        <p className="text-orange-500 text-xs justify-center text-center w-9/12">
          Please check the email or phone number associated with your account. A
          link to reset your password was sent.
        </p>
        <p className="text-orange-500 text-xs justify-center text-center w-9/12">
          The email or phone number you entered does not have a valid user
          account. Please return to the login screen to create an account.
        </p>
      </div>
    </Modal>
  );
};

export default ForgetPasswordModal;
