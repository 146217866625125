import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";

const SubscriptionCard = ({
  textsExtra,
  buttons,
  toggleFormPayment,
  subscriptions,
}) => {
  return (
    <div className="p-5">
      {subscriptions.map((subscription) => {
        // Determine if the current subscription is the Premium Subscription
        const isPremium = subscription.name === "Premium Subscription";

        return (
          <div
            key={subscription.id}
            className={`mt-4 w-full ${
              isPremium ? "bg-white text-black" : "bg-white text-black"
            } shadow-[rgba(0,_0,_0,_0.24)_0px_1px_2px] rounded-2xl flex items-center text-center flex-col space-y-5 py-4`}
          >
            <FaRegCheckCircle className="text-[4rem] mt-10" />
            <h2 className={`text-2xl font-extrabold ${isPremium ? "" : ""}`}>
              {subscription.name}
            </h2>
            <p
              className={`text-sm lg:text-lg px-14 text-justify ${
                isPremium ? "" : ""
              }`}
            >
              {subscription.description}
            </p>
            <span
              className={`text-xl font-bold ${
                isPremium ? "text-[#E7831D]" : "text-[#E7831D]"
              }`}
            >
              ${subscription.price} / {subscription.interval}
            </span>
            <button
              className={`lg:p-3 md:p-3 p-3 lg:px-20 md:px-10 px-10 ${
                isPremium
                  ? "bg-[#404041] text-white"
                  : "bg-[#404041] text-white"
              } font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg`}
              onClick={toggleFormPayment}
            >
              {buttons.subscribeNow}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default SubscriptionCard;
