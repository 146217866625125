import React, { useEffect, useState } from "react";
import { FaLinkedin, FaFacebookF, FaGoogle } from "react-icons/fa";
import { BsApple } from "react-icons/bs";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SccialLoading from "../loading/SccialLoading";
import { Toaster, toast } from "sonner";

const SocialLogin = () => {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL; // Ensure this is set in your `.env` file
  const [loading, setLoading] = useState(false); // Loader state

  // Google login handler
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true); // Start loading
      try {
        const userInfo = await axios.get(
          `https://www.googleapis.com/oauth2/v3/userinfo`,
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        const {
          given_name: first_name,
          family_name: last_name,
          email,
          sub: unique_id,
        } = userInfo.data;

        const requestBody = {
          first_name,
          last_name,
          email,
          unique_id,
          medium: "google",
          type: "job_seeker", // Default role as job seeker
          fcm_token: "",
        };

        const apiResponse = await axios.post(
          `${baseURL}/api/v1/customer/auth/social-registration`,
          requestBody
        );

        const { token } = apiResponse.data.content;
        localStorage.setItem("token", token);
        navigate("/");
      } catch (error) {
        console.error("Error during Google login:", error);
        toast.error("Login failed. Please try again.");
      } finally {
        setLoading(false); // Stop loading
      }
    },
    onError: () => {
      console.log("Google Login Failed");
      toast.error("Google login failed. Please try again.");
    },
  });

  // Facebook login handler
  const handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        // Check if the user is authenticated
        if (response.authResponse) {
          const { accessToken } = response.authResponse;

          // Fetch user info using Facebook Graph API
          window.FB.api("/me?fields=id,name,email", async (userInfo) => {
            const { name, email, id: unique_id } = userInfo;
            const [first_name, last_name] = name.split(" ");

            const requestBody = {
              first_name,
              last_name,
              email,
              unique_id,
              medium: "facebook",
              type: "job_seeker", // Default role as job seeker
              fcm_token: "",
            };

            try {
              // Send social registration request
              const apiResponse = await axios.post(
                `${baseURL}/api/v1/customer/auth/social-registration`,
                requestBody
              );

              const { token } = apiResponse.data.content;
              localStorage.setItem("token", token);
              navigate("/");
            } catch (error) {
              console.error("Error during Facebook login:", error);
              toast.error("Login failed. Please try again.");
            }
          });
        }
      },
      { scope: "email" }
    );
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "fb1148006533120155",

        status: true,
        xfbml: true,
        version: "v2.3",
      });
    };

    // Load Facebook SDK
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  if (loading) {
    return <SccialLoading />; // Display loader while loading
  }

  return (
    <div className="w-full h-auto flex justify-between items-center px-10 mt-5">
      <FaLinkedin className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" />

      <FaGoogle
        onClick={handleGoogleLogin} // Trigger Google login
        className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
      />

      <BsApple className="text-4xl text-[#404041] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" />
      <FaFacebookF
        onClick={handleFacebookLogin} // Trigger Facebook login
        className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
      />
    </div>
  );
};

export default SocialLogin;
