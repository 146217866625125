import React, { useEffect, useState, useCallback, useRef } from "react";
import { PiNumberZeroBold } from "react-icons/pi";
import Posts from "../homepage/Posts";
import { Toaster, toast } from "sonner";
import axios from "axios";
import Loading from "../loading/Loading";
import PostShimmer from "../loading/PostShimmer";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { FaSadTear } from "react-icons/fa";
import translations from "../../languages";
import UserList from "../mini-components/UserList";
import bblogo from "../../assets/276pxlogo.svg";
import mobilebarsvg from "../../assets/barsvgmobtab.svg";
import Explore from "../Explore";
import { useNavigate } from "react-router-dom";
const ExploreMain = ({ setSidebarOpen }) => {
  const navigate = useNavigate();
  const [postdatas, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [totalPosts, setTotalPosts] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const searchText = useSelector((state) => state.search.searchText);
  const activeTags = useSelector((state) => state.tag.activeTags);
  const [postLimit, setPostLimit] = useState(200);
  const [offset, setOffset] = useState(1);
  const lastApiCallTime = useRef(0);
  const [channelLoading, setChannelLoading] = useState(false);
  const [filteredPostCount, setFilteredPostCount] = useState(0);
  console.log(filteredPostCount, "filteredPostCount");

  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  const fetchPosts = useCallback(
    async (reset = false) => {
      const currentTime = Date.now();
      if (currentTime - lastApiCallTime.current < 1000) {
        return;
      }
      lastApiCallTime.current = currentTime;

      setLoading(true);
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const token = localStorage.getItem("token");
        let url, params;

        const tagParams = {};
        activeTags.forEach((tag) => {
          switch (tag) {
            case "job_seeker_post":
              tagParams.job_seeker_post = 1;
              break;
            case "emp_profile":
              tagParams.emp_profile = 1;
              break;
            case "job_seeker_profile":
              tagParams.job_seeker_profile = 1;
              break;
            case "emp_post":
              tagParams.emp_post = 1;
              break;
            default:
              break;
          }
        });

        if (token) {
          url = `${baseURL}/api/v1/user/post/list`;
          params = {
            limit: 10, // Limit to 10 posts per API call
            string: searchText,
            offset: offset,
            ...tagParams,
          };
        } else {
          url = `${baseURL}/api/v1/user/post/listoffline`;
          params = {
            limit: 10, // Limit to 10 posts per API call
            string: searchText,
            offset: offset,
            ...tagParams,
          };
        }

        const response = await axios.get(url, {
          params,
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        const postDataStructure = response.data.content.data;
        console.log(postDataStructure, "postDataStructure");
        const total = response.data.content.total;

        setTotalPosts(total);
        setPosts((prev) =>
          reset ? postDataStructure : [...prev, ...postDataStructure]
        );

        if (Object.keys(tagParams).length > 0 || searchText.trim() !== "") {
          setFilteredPostCount(postDataStructure.length);
        } else {
          setFilteredPostCount(0);
        }

        setLoading(false);
        setFetching(false);
      } catch (err) {
        setLoading(false);
        setFetching(false);
      }
    },
    [offset, searchText, activeTags, totalPosts]
  );

  const debouncedFetchPosts = useCallback(
    debounce((reset) => {
      fetchPosts(reset);
    }, 300),
    [fetchPosts]
  );

  const handleInfiniteScroll = async () => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      const lastPost = scrollContainerRef.current.lastChild; // Assuming lastChild is the last post
      const lastPostOffset = lastPost.offsetTop + lastPost.clientHeight;

      // Check if the last post is in view and trigger fetching more posts
      if (scrollTop + clientHeight >= lastPostOffset) {
        if (!fetching && postdatas.length < totalPosts) {
          setFetching(true);
          setOffset((prev) => prev + 1); // Increment offset to fetch next batch
        }
      }
    }
  };
  useEffect(() => {
    const refreshScrollListener = () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
      window.addEventListener("scroll", handleInfiniteScroll);
    };
    refreshScrollListener();
  }, [fetching]);

  useEffect(() => {
    fetchPosts(true);
  }, [postLimit, fetchPosts]);

  useEffect(() => {
    if (searchText.length === 0) {
      setPostLimit(100);
      debouncedFetchPosts(true);
    } else if (searchText.length >= 3) {
      setPostLimit(100);
      debouncedFetchPosts(true);
    }
  }, [searchText, debouncedFetchPosts]);

  useEffect(() => {
    const refreshScrollListener = () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
      window.addEventListener("scroll", handleInfiniteScroll);
    };
    refreshScrollListener();
  }, [fetching]);

  const handleBookmark = async (userId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/store`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(" User bookmarked successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
    } catch (error) {
      console.error("Error bookmarking user:", error);
      toast.error("Error bookmarking user. Please try again later.");
    }
  };

  const handleReportPost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/user-action`,
        { message: "block", post_id: postId, comment_type: "block" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Post reported successfully");
    } catch (error) {
      console.error("Error reporting post:", error);
      toast.error("Error reporting post. Please try again later.");
    }
  };
  const handleDeletePost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/delete/${postId}`,

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(" Post deleted successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      fetchPosts(true);
      // handleNewPostCreation();
    } catch (error) {
      console.error("Error deleted post:", error);
      toast.error("Error deleted post. Please try again later.");
    }
  };
  const createChatChannel = async (postId, toUser) => {
    try {
      setChannelLoading(true);
      const authToken = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/chat/create-channel`;
      const data = {
        reference_id: "",
        reference_type: "support",
        post_id: postId,
        to_user: toUser,
      };

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const channelId = response.data.content.id;

      setChannelLoading(false);

      toast(" Chat channel created successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });

      navigate(`/dm/${channelId}/${toUser}`);
    } catch (error) {
      setChannelLoading(false);
      toast.error("Error creating chat channel. Please try again later.");
    }
  };
  return (
    <div className="scrollbar-hide w-auto paddingy mobile-top">
      <div className="flex items-center justify-between mb-3 w-full relative bg-white sticky-top">
        <div className="absolute left-0">
          <img
            src={mobilebarsvg}
            alt="logo"
            className="w-8 cursor-pointer hidelarge bottom-navigation ml-5"
            onClick={() => setSidebarOpen(true)}
          />
        </div>
        <div className="flex justify-center w-full">
          <img src={bblogo} className="h-8 hidelarge" />
        </div>
      </div>
      <h1 className="text-lg font-bold mb-2 px-8 sidebar-complete-hide">
        {texts.explore}
      </h1>
      {postdatas.length !== 0 && (
        <div>
          {filteredPostCount > 0 && (
            <p className=" italic text-neutral-600 px-8 text-xs">
              <span className="font-bold">{searchText} </span>

              {filteredPostCount > 0
                ? `${filteredPostCount} results found`
                : ""}
            </p>
          )}
        </div>
      )}
      {postdatas.length === 0 && !fetching && (
        <div>
          {filteredPostCount === 0 && (
            <p className=" italic text-neutral-600 px-8 text-xs">
              <span className="font-bold">{searchText} </span>{" "}
              {filteredPostCount} results found
            </p>
          )}
        </div>
      )}

      <div class="exploreDiv">
        <Explore />
      </div>
      <hr />
      <UserList />
      {loading && !fetching ? (
        <div className="flex flex-col">
          <PostShimmer />
          <PostShimmer />
          <PostShimmer />
        </div>
      ) : postdatas.length === 0 && !fetching ? (
        <div className="flex flex-col items-center justify-center mt-20">
          {/* <PiNumberZeroBold size={70} className="text-[#E7831D] mb-4" /> */}
          <p className="text-xl text-neutral-600">
            Try searching something else
          </p>
        </div>
      ) : (
        <Posts
          postdatas={postdatas}
          handleBookmark={handleBookmark}
          handleReportPost={handleReportPost}
          createChatChannel={createChatChannel}
          handleDeletePost={handleDeletePost}
          channelLoading={channelLoading}
        />
      )}
      {fetching && <Loading />}
    </div>
  );
};

export default ExploreMain;
