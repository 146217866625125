import { Fragment } from "react";
import moment from "moment";
import { TimePicker } from "antd"; // Import TimePicker from AntD
import { FaChevronDown } from "react-icons/fa";

const TimeOptionTwo = ({ selectedTime, setSelectedTime, heading }) => {
  const handleSelectTime = (time) => {
    if (time) {
      const formattedTime = time.format("HH:mm:ss");
      setSelectedTime(formattedTime);
    } else {
      setSelectedTime(null);
    }
  };

  const handleOpenChange = (open) => {
    if (open) {
      setSelectedTime(null);
    }
  };

  const handleConfirm = () => {
    setSelectedTime(selectedTime);
  };

  const handleCancel = () => {
    setSelectedTime(null);
  };

  const renderExtraFooter = () => (
    <div className="flex justify-between h-10 py-2">
      <button
        className="rounded-md bg-neutral-600 text-white px-2 text-xs"
        onClick={handleCancel}
      >
        Cancel
      </button>
      <button
        className="rounded-md text-white px-2 text-xs bg-[#E7831D]"
        onClick={handleConfirm}
      >
        Confirm
      </button>
    </div>
  );

  return (
    <div className="relative inline-block w-full">
      <p className="text-[15.85px] font-bold mt-3 mb-1 text-[#424242]">
        {heading} <span className="text-neutral-600 text-xs">(Optional)</span>
      </p>
      <TimePicker
        value={selectedTime ? moment(selectedTime, "HH:mm:ss") : null}
        onChange={handleSelectTime}
        onOpenChange={handleOpenChange}
        format="hh:mm A"
        className="w-full px-4 py-2 bg-white text-sm font-medium text-neutral-700 hover:bg-neutral-50  hover:border-neutral-200 border-2 rounded-full focus:outline-none focus:ring-0 focus:border-neutral-300"
        suffixIcon={<FaChevronDown className="text-neutral-400 " />}
        dropdownClassName="custom-dropdown"
        allowClear={true}
        showNow={false}
        renderExtraFooter={renderExtraFooter}
        clearIcon={<span className="text-sm">Cancel</span>}
      />
    </div>
  );
};

export default TimeOptionTwo;
