import { useState } from "react";
import moment from "moment";
import { TimePicker, ConfigProvider } from "antd";
import { FaChevronDown } from "react-icons/fa";

const TimeDropdown = ({ selectedTime, setSelectedTime, heading }) => {
  const [isOpen, setIsOpen] = useState(false); // State to control the open state of the TimePicker
  const [tempTime, setTempTime] = useState(null); // Temporary time for confirmation
  console.log(tempTime, "tempTime");

  const handleSelectTime = (time) => {
    setTempTime(time); // Store the selected time temporarily
  };

  const handleOpenChange = (open) => {
    setIsOpen(open);
    if (!open) {
      setTempTime(null); // Clear temporary time when the picker closes
    }
  };

  const handleConfirm = () => {
    setSelectedTime(tempTime); // Set the selected time
    setIsOpen(false); // Close the TimePicker
  };

  const handleCancel = () => {
    setTempTime(null); // Clear temporary time
    setIsOpen(false); // Close the TimePicker
  };

  const renderExtraFooter = () => (
    <div className="flex justify-between h-10 py-2">
      <button
        className="rounded-md bg-neutral-600 text-white px-2 text-xs"
        onClick={handleCancel}
      >
        Cancel
      </button>
      <button
        className="rounded-md text-white px-2 text-xs bg-[#E7831D]"
        onClick={handleConfirm}
      >
        Confirm
      </button>
    </div>
  );

  return (
    <ConfigProvider>
      <div className="relative inline-block w-full">
        <p className="text-[15.85px] font-bold mt-3 mb-1 text-[#424242]">
          {heading}
        </p>
        <TimePicker
          open={isOpen}
          onOpenChange={handleOpenChange}
          value={
            tempTime || (selectedTime ? moment(selectedTime, "HH:mm:ss") : null)
          }
          onChange={handleSelectTime}
          format="hh:mm A"
          className="w-full px-4 py-2 bg-white text-sm font-medium hover:border-neutral-200 text-neutral-700 hover:bg-neutral-50 border-2 rounded-full focus:outline-none focus:ring-0 focus:border-neutral-300"
          suffixIcon={<FaChevronDown className="text-neutral-400" />}
          dropdownClassName="custom-dropdown"
          allowClear={true}
          renderExtraFooter={renderExtraFooter}
          hideDisabledOptions
          showNow={false}
          clearIcon={<span className="text-sm">Cancel</span>}
        />
      </div>
    </ConfigProvider>
  );
};

export default TimeDropdown;
