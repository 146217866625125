import React from "react";
import { FaEdit } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { FaRegMap } from "react-icons/fa";
import { IoMdGlobe } from "react-icons/io";
import { HiMiniLanguage } from "react-icons/hi2";
import { LuLink } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import translations from "../../languages";

const SettingsListThree = () => {
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].settingsThree;
  const settingsThree = [
    {
      name: texts.termsAndConditions,
      canEdit: false,
      href: "/set/policy/:name",
      icon: LuLink,
    },

    {
      name: texts.privacyPolicy,
      canEdit: false,
      icon: LuLink,
      href: "/set/policy/:name",
    },
    {
      name: texts.cancellationPolicy,
      canEdit: false,
      icon: LuLink,
      href: "/set/policy/:name",
    },
  ];

  const getPolicyUrl = (name) => {
    switch (name) {
      case "Terms & Conditions":
        return "/set/policy/terms_and_conditions";
      case "Privacy Policy":
        return "/set/policy/privacy_policy";
      case "Cancellation & Refund Policy":
        return "/set/policy/cancellation_&_refund_policy";
      default:
        return "/set/policy/:name";
    }
  };
  return (
    <ul
      role="list"
      className="divide-y divide-neutral-100 overflow-hidden bg-white ring-neutral-900/5 sm:rounded-xl"
    >
      {settingsThree.map((item) => (
        <li
          key={item.name}
          className="relative flex justify-between gap-x-6 px-10 py-5 sm:px-8 hover:scale-105 duration-300"
        >
          <Link to={getPolicyUrl(item.name)}>
            <div className="flex gap-x-4">
              <item.icon className="text-2xl" />
              <div className="min-w-0 flex-auto">
                <p className="text-md leading-6 text-neutral-700">
                  <span className="absolute inset-x-0 -top-px bottom-0 text-neutral-600" />
                  {item.name}
                </p>
                <p className="text-sm text-orange-500">{texts.read}</p>
                <p className="mt-1 flex text-xs leading-5 text-neutral-500"></p>
              </div>
            </div>
            <div className="flex items-center gap-x-4 cursor-pointer hover:scale-105">
              {item.canEdit && (
                <FaEdit
                  className="h-5 w-5 flex-none text-neutral-400 cursor-pointer hover:scale-105"
                  aria-hidden="true"
                />
              )}
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SettingsListThree;
