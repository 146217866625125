import React from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import { BsThreeDots } from "react-icons/bs";
import { FaBookmark } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import avatar from "../../assets/lady.jpg";
import { GoDotFill } from "react-icons/go";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { clearSearchText } from "../../redux-store/postSearch/searchSlice"; // import clear actions
import { clearUserSearchText } from "../../redux-store/userSearch/userSearchSlice"; // import clear actions

const AllUserList = ({ data }) => {
  const dispatch = useDispatch();
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const popUpAnimation = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };
  const handleLinkClick = () => {
    dispatch(clearSearchText());
    dispatch(clearUserSearchText());
  };
  return (
    <div className="w-full ">
      {data.length > 0 ? (
        data.map((post, index) => (
          <motion.div
            key={index}
            className="p-3 px-8 bg-white rounded-xl mb-3 w-full scrollbar-hide "
            initial="hidden"
            animate="visible"
            variants={popUpAnimation}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <div className="hover:scale-105 ease-in-out transition-transform duration-300 mb-7">
              <div className="flex w-full ">
                {post.profile_image &&
                  post.profile_image !== "default.png" &&
                  post.profile_image !== "" && (
                    <img
                      className={`w-12 h-12 rounded-full p-0 object-cover text-orange-500 ${
                        post.type?.toLowerCase() === "employer"
                          ? "ring-2 ring-orange-500"
                          : "ring-2 ring-neutral-700"
                      }`}
                      src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${post.profile_image}`}
                      alt="User Avatar"
                      style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                    />
                  )}

                {(!post.profile_image ||
                  post.profile_image === "default.png" ||
                  post.profile_image === "") && (
                  <FaUserCircle
                    className={`text-5xl rounded-full  text-neutral-400 p-[0.08rem]  ${
                      post.type?.toLowerCase() === "employer"
                        ? "ring-2 ring-orange-500"
                        : "ring-2 ring-neutral-700"
                    }`}
                  />
                )}
                <div className="flex flex-col ml-2 mb-2 w-full">
                  <Link
                    className="text-lg font-bold text-neutral-600"
                    to={`/user/${post.id}`}
                    onClick={handleLinkClick}
                  >
                    {post.first_name} {post.last_name}
                  </Link>
                  <div
                    className={`text-sm flex items-center text-[#5B5B5B] ${
                      post.username === "@premium" || post.username === "@Test"
                        ? "text-white"
                        : ""
                    }`}
                  >
                    {post.username}
                    <GoDotFill className="ml-1 text-xs mr-1" />
                    <span
                      className={`text-sm flex items-center ${
                        post.type === "job-seeker"
                          ? "text-black"
                          : "text-orange-400"
                      }`}
                    >
                      {capitalizeFirstLetter(post.type)}
                      <IoIosCheckmarkCircle className="ml-1" />
                    </span>
                  </div>
                </div>
              </div>
              {post.about_me && (
                <div className="w-full px-14 mb-5">
                  {(() => {
                    const truncatedText =
                      post.about_me.length > 100
                        ? post.about_me.substring(0, 100) + "..."
                        : post.about_me;

                    return truncatedText.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line.split(/(\s+)/).map((part, i) =>
                          part.startsWith("#") ? (
                            <span
                              key={i}
                              className="text-orange-400 font-bold cursor-pointer transition-transform ease-in-out duration-300"
                            >
                              {part}
                            </span>
                          ) : (
                            part
                          )
                        )}
                        <br />
                      </React.Fragment>
                    ));
                  })()}
                </div>
              )}
            </div>

            <hr />
          </motion.div>
        ))
      ) : (
        <div> </div>
      )}
    </div>
  );
};

export default AllUserList;
