import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import axios from "axios";
import { Toaster, toast } from "sonner";

import messagedata from "../messagePage/messageData";
import postdata from "../homepage/postData";
import ProfileTabData from "./ProfileTabData";
import AllMessages from "../messagePage/AllMessages";
import ProfileTab from "./ProfileTab";
import Posts from "../homepage/Posts";
import PostBookmarks from "../homepage/PostBookmarks";
import Loading from "../loading/Loading";
import PostShimmer from "../loading/PostShimmer";
import GuestModeModal from "../../pages/GuestModeModal";
import SmallSpinner from "../loading/SmallSpinner";
import UserList from "../mini-components/UserList";
import translations from "../../languages";

const TabsNavigation = () => {
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].bookmarkOptions;

  const navigate = useNavigate();
  const [postdatas, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [totalPosts, setTotalPosts] = useState(null);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [bookmarkedPosts, setBookmarkedPosts] = useState(new Set());
  const [userExists, setUserExists] = useState(false);
  const searchText = useSelector((state) => state.search.searchText);
  const activeTags = useSelector((state) => state.tag.activeTags);
  const [postLimit, setPostLimit] = useState(10);
  const lastApiCallTime = useRef(0);
  const [channelLoading, setChannelLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    } else {
      setUserExists(false);
      toast(
        " You are currently in guest mode. Some features are limited. To unlock the full functionality, please log in or create a free account.",
        {
          style: {
            backgroundColor: "#333", // neutral background
            color: "#fff", // White text
          },
        }
      );
    }
  }, []);

  const fetchPosts = useCallback(
    async (reset = false) => {
      const currentTime = Date.now();
      if (currentTime - lastApiCallTime.current < 1000) {
        return; // If the last API call was made less than 1 second ago, do nothing
      }
      lastApiCallTime.current = currentTime;

      setLoading(true);
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const token = localStorage.getItem("token");
        let url, params;

        const tagParams = {};
        activeTags.forEach((tag) => {
          switch (tag) {
            case "job_seeker_post":
              tagParams.job_seeker_post = 1;
              break;
            case "emp_profile":
              tagParams.emp_profile = 1;
              break;
            case "job_seeker_profile":
              tagParams.job_seeker_profile = 1;
              break;
            case "emp_post":
              tagParams.emp_post = 1;
              break;
            default:
              break;
          }
        });

        if (token) {
          url = `${baseURL}/api/v1/user/post/list`;
          params = {
            limit: postLimit,
            string: searchText,
            bookmark: "yes",
            offset: 1,
            ...tagParams,
          };
        } else {
          url = `${baseURL}/api/v1/user/post/listoffline`;
          params = {
            limit: postLimit,
            string: searchText,
            offset: 1,
            ...tagParams,
          };
        }

        const response = await axios.get(url, {
          params,
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        const postDataStructure = response.data.content.data;
        const total = response.data.content.total;
        const bookmarkedSet = new Set();
        postDataStructure.forEach((post) => {
          if (post.bookmark_status.length > 0) {
            bookmarkedSet.add(post.id);
          }
        });
        setBookmarkedPosts(bookmarkedSet);
        setTotalPosts(total);
        if (reset) {
          setPosts(postDataStructure);
        } else {
          setPosts((prev) => {
            const newPosts = postDataStructure.filter(
              (post) =>
                !prev.some((existingPost) => existingPost.id === post.id)
            );
            return [...prev, ...newPosts];
          });
        }

        setLoading(false);
        setFetching(false);
      } catch (err) {
        setLoading(false);
        setFetching(false);
      }
    },
    [postLimit, searchText, activeTags, totalPosts]
  );

  const debouncedFetchPosts = useCallback(
    debounce((reset) => {
      fetchPosts(reset);
    }, 300),
    [fetchPosts]
  );

  const handleInfiniteScroll = async () => {
    if (totalPosts && postdatas.length >= totalPosts) {
      return; //! Stop fetching more posts if we have already fetched all posts
    }

    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        if (!fetching) {
          setFetching(true);
          setPostLimit((prev) => prev + 10);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);

  useEffect(() => {
    fetchPosts(true);
  }, [postLimit, fetchPosts, totalPosts]);

  useEffect(() => {
    if (searchText.length === 0) {
      setPostLimit(10);
      debouncedFetchPosts(true);
    } else if (searchText.length >= 3) {
      setPostLimit(100);
      debouncedFetchPosts(true);
    }
  }, [searchText, debouncedFetchPosts]);

  useEffect(() => {
    const refreshScrollListener = () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
      window.addEventListener("scroll", handleInfiniteScroll);
    };
    refreshScrollListener();
  }, [fetching]);

  useEffect(() => {
    if (searchText.length > 0) {
      navigate("/explore");
    }
  }, [searchText, navigate]);

  const handleBookmark = async (userId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/profile/bookmark/store`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(" User bookmarked successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
    } catch (error) {
      console.error("Error bookmarking user:", error);
      toast.error("Error bookmarking user. Please try again later.");
    }
  };

  const handleReportPost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/user-action`,
        { message: "block", post_id: postId, comment_type: "block" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Post reported successfully");
    } catch (error) {
      console.error("Error reporting post:", error);
      toast.error("Error reporting post. Please try again later.");
    }
  };
  const handleDeletePost = async (postId) => {
    try {
      const authToken = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/delete/${postId}`,

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast(" Post deleted successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });
      fetchPosts(true);
    } catch (error) {
      console.error("Error deleted post:", error);
      toast.error("Error deleted post. Please try again later.");
    }
  };

  const createChatChannel = async (postId, toUser) => {
    try {
      setChannelLoading((prev) => ({ ...prev, [postId]: true }));
      const authToken = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/chat/create-channel`;
      const data = {
        reference_id: "",
        reference_type: "support",
        post_id: postId,
        to_user: toUser,
      };

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const channelId = response.data.content.id;

      setChannelLoading((prev) => ({ ...prev, [postId]: false }));

      toast(" Chat channel created successfully", {
        style: {
          backgroundColor: "#333", // neutral background
          color: "#fff", // White text
        },
      });

      navigate(`/dm/${channelId}/${toUser}`);
    } catch (error) {
      setChannelLoading((prev) => ({ ...prev, [postId]: false }));
      toast.error("Error creating chat channel. Please try again later.");
    }
  };
  const tabs = [
    {
      name: texts.chats,
      component: <AllMessages messagedata={messagedata} />,
      current: true,
    },
    {
      name: texts.posts,
      component: (
        <>
          {loading && !fetching ? (
            <div className="flex flex-col">
              <PostShimmer />
              <PostShimmer />
              <PostShimmer />
            </div>
          ) : (
            <Posts
              postdatas={postdatas}
              loading={loading}
              fetchPosts={fetchPosts}
              handleBookmark={handleBookmark}
              handleReportPost={handleReportPost}
              createChatChannel={createChatChannel}
              channelLoading={channelLoading}
              handleDeletePost={handleDeletePost}
              setFetching={setFetching}
              bookmarkedPosts={bookmarkedPosts}
            />
          )}
        </>
      ),
    },
    {
      name: texts.profiles,
      component: <ProfileTab ProfileTabData={ProfileTabData} />,
      current: false,
    },
  ];

  const [activeTab, setActiveTab] = useState(
    tabs.find((tab) => tab.current).name
  );

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>

        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-neutral-300 focus:border-neutral-500 focus:ring-neutral-500 p-5 outline-none bg-neutral-100"
          value={activeTab}
          onChange={(e) => handleTabClick(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name} className="p-5">
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-neutral-200">
          <nav className="-mb-px flex px-6 py-3" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => handleTabClick(tab.name)}
                className={`flex py-1 rounded-full cursor-pointer text-sm justify-center items-center w-[9.85rem] h-11 overflow-hidden mx-8 ${
                  tab.name === activeTab
                    ? "bg-[#E7831D] text-white border-none"
                    : "bg-gradient-to-r from-neutral-50 to-white text-neutral-600 border-neutral-400 border"
                }`}
                aria-current={tab.name === activeTab ? "page" : undefined}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }}
                >
                  {tab.name}
                </span>
              </div>
            ))}
          </nav>
        </div>
      </div>

      {tabs.find((tab) => tab.name === activeTab).component}
    </div>
  );
};

export default TabsNavigation;
