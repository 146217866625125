// InterviewScheduleModal.js
import React from "react";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";

import MonthViewModal from "../../mini-components/MonthViewModal";

const InterviewScheduleModal = ({ isVisible, onClose, channelid }) => {
  const options = ["Day view", "Month view"];
  return (
    <Modal
      //   title="Schedule Interview"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      <div className="p-5">
        <MonthViewModal
          options={options}
          onClose={onClose}
          channelid={channelid}
        />
      </div>
    </Modal>
  );
};

export default InterviewScheduleModal;
